<template>
  <div>
    <v-bottom-navigation
      v-if="isAuthenticated"
      v-show="displayXS"
      app
      class="v-bottom-navigation"
      style="z-index: 120"
    >

      <v-btn to="/">
        <span>{{ $t('projects') }}</span>
        <v-icon>apps</v-icon>
      </v-btn>

      <v-btn @click="search">
        <span>{{ $t('search') }}</span>
        <v-icon>search</v-icon>
      </v-btn>

      <v-btn @click="showChats">
        <span>{{ $t('chats') }}</span>
        <v-icon>forum</v-icon>
      </v-btn>

      <v-btn to="/profile">
        <span>{{ $t('profile') }}</span>
        <v-icon>account_circle</v-icon>
      </v-btn>

    </v-bottom-navigation>

    <v-navigation-drawer
      v-if="isAuthenticated"
      app
      class="app-navigation elevation-1 primary-bar"
      dark
      mini-variant
      mini-variant-width="48"
      mobile-break-point="600">

      <img :src="logo"
           class="logo pointer"
           @click="goHome"/>

      <v-list>
        <v-list-item
          :ripple="false"
          @click="search"
        >
          <v-list-item-icon>
            <v-tooltip
              color="black"
              nudge-bottom="5"
              nudge-right="5"
              right
            >
              <template v-slot:activator="{ on }">
                <v-icon color="primary-bar-text" v-on="on">search</v-icon>
              </template>
              <span>{{ $t('search') }}</span>
            </v-tooltip>
          </v-list-item-icon>
        </v-list-item>

        <div
          class="text-center pb-2"
          style="font-size: 9px; color: var(--v-primary-bar-text-base); margin-top: -12px; width: 100%"
        >
          Ctrl + S
        </div>

        <v-list-item :ripple="false"
                     @click="showChats">
          <v-list-item-icon>
            <v-tooltip color="black"
                       nudge-right="5"
                       right>
              <template v-slot:activator="{ on }">
                <v-badge :content="totalNumberOfUnreadMessages"
                         :value="totalNumberOfUnreadMessages"
                         bottom
                         color="blue"
                         overlap
                         transition="">
                  <v-icon color="primary-bar-text" v-on="on">forum</v-icon>
                </v-badge>
              </template>
              <span>{{ $t('chats') }}</span>
            </v-tooltip>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item :ripple="false" @click="openDrive">
          <v-list-item-icon>
            <v-tooltip color="black" nudge-right="5" right>
              <template v-slot:activator="{ on }">
                <v-icon color="primary-bar-text" v-on="on">
                  cloud
                </v-icon>
              </template>
              <span>{{ $t('files') }}</span>
            </v-tooltip>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item :ripple="false" @click="openKnowledgeBase">
          <v-list-item-icon>
            <v-tooltip color="black"
                       nudge-right="5"
                       right>
              <template v-slot:activator="{ on }">
                <v-icon color="primary-bar-text" v-on="on">assignment</v-icon>
              </template>
              <span>{{ $t('knowledgeBase') }}</span>
            </v-tooltip>
          </v-list-item-icon>
        </v-list-item>

        <v-divider class="my-1"/>

        <v-list-item :ripple="false"
                     class="py-0 my-0"
                     dense
                     to="/">
          <v-list-item-icon>
            <v-tooltip color="black"
                       nudge-right="5"
                       right>
              <template v-slot:activator="{ on }">
                <v-icon color="primary-bar-text" v-on="on">mdi-apps</v-icon>
              </template>
              <span>{{ $t('projects') }}</span>
            </v-tooltip>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item v-for="project in favoriteProjects"
                     :ripple="false"
                     class="pa-0 mb-2"
                     @click="select(project)">
          <v-tooltip color="black"
                     right>
            <template v-slot:activator="{ on }">
              <div :style="projectBackground(project.name)"
                   class="project-avatar"
                   v-on="on">
                <span class="white--text"
                      v-on="on">{{ project.name[0] }}</span>
              </div>
            </template>
            <span>{{ $t('project') }} <b>{{ project.name }}</b></span>
          </v-tooltip>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-list>
          <v-list-item :ripple="false">
            <v-menu :nudge-right="5"
                    :nudge-width="200"
                    color="black"
                    offset-x>
              <template v-slot:activator="{ on }">
                <v-list-item
                  icon
                  v-on="on">
                  <v-icon color="primary-bar-text">language</v-icon>
                </v-list-item>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, i) in languages"
                  :key="i"
                  @click="setLocale(item.value)">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>

          <v-list-item
            v-if="setAbility('SYSTEM_ADMIN')"
            :ripple="false"
            to="/admin/users"
          >
            <v-list-item-icon>
              <v-icon color="primary-bar-text">settings</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t('administration') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-menu :nudge-right="5"
                  :nudge-width="200"
                  color="black"
                  offset-x>
            <template v-slot:activator="{ on }">
              <v-list-item v-if="isAuthenticated" class="mb-2" v-on="on">
                <user-avatar :user="currentLoginUser" class="mx-2"/>
              </v-list-item>
            </template>
            <v-card>
              <profile-items/>
            </v-card>
          </v-menu>
        </v-list>
      </template>
    </v-navigation-drawer>

    <global-search ref="searchDialog"/>
    <drive ref="drive"/>
    <article-editor ref="knowledgeBase"/>
  </div>
</template>

<script>
import ProfileItems from '@/components/profile/ProfileItems'
import GlobalSearch from '@/components/search/GlobalSearch'
import UserAvatar from '@/components/utils/UserAvatar'
import { EventBus } from '@/event-bus'
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import messages from '../componet-locale/navigation-bar/messages'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import { languages, setLocale } from '@/services/locale-switcher'
import Drive from '@/components/drive/Drive.vue'
import ArticleEditor from '@/components/wiki/ArticleEditor.vue'

export default {
  name: 'NavigationBar',
  components: { ArticleEditor, Drive, ProfileItems, UserAvatar, GlobalSearch },
  mixins: [displayServiceMixin],
  data: () => ({
    drawer: false,
    favoriteProjects: [],
    logo: '',
    languages: languages
  }),
  i18n: { messages: messages },
  mounted () {
    axios
      .get('settings/get-general')
      .then(response => this.logo = response.data.COMPANY_FAVICON)

    document.addEventListener('keydown', this.keydownListener)
    EventBus.$on('changeFavoriteProject', () => this.getFavoriteProjects())
    this.getFavoriteProjects()
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.keydownListener)
  },
  methods: {
    ...mapActions([
      'selectProject'
    ]),
    showChats () {
      EventBus.$emit('show-chats')
    },
    select (project) {
      this.selectProject({
        project: project,
        path: 'home'
      })
    },
    setLocale (locale) {
      setLocale(locale)
    },
    getFavoriteProjects () {
      this.$axios
        .get('project/get-favorite-project-list')
        .then(response => this.favoriteProjects = response.data)
    },
    projectBackground (projectName) {
      let hash = 0
      for (let i = 0; i < projectName.length; i++) {
        hash = projectName.charCodeAt(i) + ((hash << 5) - hash)
        hash = hash&hash
      }
      hash = Math.abs(hash)
      const leftColor = hash % 360
      const rightColor = hash + 50
      return { 'background-image': `linear-gradient(to bottom right, hsla(${leftColor}, 70%, 50%), hsl(${rightColor}, 70%, 50%))` }
    },
    search () {
      this.$refs.searchDialog.open()
    },
    openDrive () {
      this.$refs.drive.open()
    },
    openKnowledgeBase () {
      this.$refs.knowledgeBase.open()
    },
    keydownListener (e) {
      if (!(e.keyCode === 83 && e.ctrlKey)) {
        return
      }
      e.preventDefault()
      this.search()
    },
    goHome () {
      if (this.$route.path !== '/') this.$router.push('/')
    }
  },
  computed: {
    ...mapGetters([
      'totalNumberOfUnreadMessages'
    ])
  },
  watch: {
    currentLoginUser (val) {
      if (val) this.getFavoriteProjects()
    }
  }
}
</script>

<style>
.app-navigation .v-list-item .v-icon {
  opacity: 0.5
}

.app-navigation .v-list-item:hover .v-icon {
  opacity: 0.8
}

.app-navigation .v-list-item--active .v-icon,
.app-navigation .v-list-item--active:hover .v-icon {
  opacity: 1;
}

.app-navigation .v-list-item:hover::before,
.app-navigation .v-list-item--active::before {
  opacity: 0;
}

.app-navigation .v-list-item .v-icon {
  opacity: 1 !important;
}

.app-navigation .v-list-item:hover .v-icon {
  opacity: 0.6 !important;
}

</style>
